
<template>
   <Swiper
    v-if="list.length>0"
    :autoPlay='autoPlay'
    :showIndicator='showIndicator'
    :interval="interval"
    :duration="duration"
    >


    
       <Slide v-for="(item,index) in list" :key="index"  @click="clickSwiper(item)">
         <img :src=" item.img_url"/>
          <p>{{item.title}}</p>
       </Slide>
  </Swiper>
</template>
 
<script>
import { Swiper, Slide } from 'vue-swiper-component';
export default {
  props: {
    /**
     * 轮播图数据
     */
    list:Array,
    /**
     * 是否自动轮播
     */
    autoPlay:{
      type:Boolean,
      default:false
    },
    /**
     * 是否显示轮播的点
     */
    showIndicator:{
      type:Boolean,
      default:false
    },
     /**
     * 每两次隔多久滚动一次
     */
    interval:{
      type:Number,
      default:2500
    },
     /**
     * 每次滚动一页需要多久
     */
    duration:{
      type:Number,
      default:500
    },
  },
  data () {
    return {
 
    };
  },
 
  components: {
    Swiper,
    Slide
  },
 
  computed: {},
 
  mounted() {},
 
  methods: {
    /**
     * 轮播图点击事件
     */
    clickSwiper(data){
      this.$emit('clickSwiper',data);
    }
  },
 
  watch: {}
}
 
</script>
<style lang='less' scoped>
 
</style>
————————————————
版权声明：本文为CSDN博主「浩星」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
原文链接：https://blog.csdn.net/qq_41619796/article/details/102864554